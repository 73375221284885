'use client';

import { Header } from './_PageSections/Header';
import { LayoutProps } from '@/lib/types/types';
import Footer from '@/components/Footer';
import { usePathname } from 'next/navigation';

export default function MarketingLayout({ children }: LayoutProps) {
  const pathname = usePathname();
  const isFAQPage = pathname === '/faq';
  
  return (
    // <div className="bg-gradient-to-br from-green-400 via-teal-500 to-blue-600 h-screen text-red-900 mx-2">
      // <div className="bg-gradient-to-b from-green-400 via-teal-400 via-teal-500 via-blue-400 to-blue-600 h-screen">
      <div className="bg-gradient-to-b from-orange-400 via-amber-500 via-rose-500 to-purple-600 h-screen">
      <div>
        <Header />
      </div>
      {isFAQPage ? (
    <main className="w-full pt-20">{children}</main>
  ) : (
    <main className="grid items-center justify-center pt-20">
      {children}
    </main>
  )}
      {/* <main className="grid items-center justify-center pt-20">{children}</main> */}
      {/* <main className="grid pt-20">{children}</main> */}
      <Footer />
    </div>
  );
}
